import service from "@/utils/request";


//分页查询所有
export const getRefundOrderInfoData = (params) => service({
    url: '/RefundOrder/queryPage',
    method: "get",
    params: params
});

